@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Nunito:wght@500;600&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

/* p,
span {
  @apply text-slate-500;
} */

html,
body,
#__next {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-slate-50;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
  @apply font-semibold;
}

.MuiButton-variantSolid {
  @apply bg-cyan-600 hover:bg-cyan-700 transition-all;
}

.MuiButton-colorDanger {
  @apply bg-red-600 hover:bg-red-700 transition-all;
}

input:focus,
textarea:focus {
  @apply outline-none;
}

.active {
  @apply border-cyan-700 text-cyan-700;
}

.color-input .MuiInput-input {
  @apply h-full border-none outline-none rounded-none;
}

.filepond--drop-label {
  @apply bg-white rounded border-dashed border-2 border-slate-200;
  @apply hover:bg-slate-50 cursor-pointer;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #088ab2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 0.75s linear infinite;
}

.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #088ab2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 0.75s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

.MuiButton-containedPrimary {
  @apply bg-cyan-600 hover:bg-cyan-800;
}

.MuiDrawer-content {
  @apply w-full max-w-lg;
}

/* Designer: Overriding some styles */
.MuiTooltip-popper.datasource-helper .MuiTooltip-tooltip {
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0px 0px 5px #cecece;
  color: #000;
  max-width: 360px;
}

.phone .MuiInput-startDecorator {
  display: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-3;
}

.fc-toolbar-chunk {
  @apply bg-white border border-slate-200 rounded;
}

.fc .fc-view-harness {
  @apply bg-white;
}

.fc .fc-scrollgrid-liquid {
  @apply rounded;
}

.fc-col-header {
  @apply bg-slate-200;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  @apply bg-sky-500 bg-opacity-10;
}

.fc-event {
  background-color: #bae6fd !important;
  border-color: #bae6fd !important;
}

.fc-event-title,
.fc-event-time {
  @apply text-sky-950 font-medium;
}

.fc-scrollgrid-sync-table tbody tr {
  height: 60px !important;
}

.MuiButton-variantOutlined .MuiButton-colorNeutral {
  @apply bg-white;
}

.fc-button-active {
  background-color: #088ab2 !important;
  color: #ffffff !important;
}
